<template>
<div>
  <ion-button @click="$router.push('/quiz')" expand="block" mode="ios" color="dark"  class="btn" v-if="! timer || ! $auth">Kumpulkan Poin</ion-button>
  <ion-button expand="block" mode="ios" color="dark"  class="btn"  v-if="timer && $auth" disabled>{{ text }}</ion-button>
</div>
</template>

<script>
import { IonButton } from '@ionic/vue'
import { mapGetters } from 'vuex'

export default {
  components: {
    IonButton
  },
  data () {
    return {
      timer: 0,
      text: ''
    }
  },
  watch: {
    '$route': 'getWaitingTime'
  },
  mounted () {
    this.getWaitingTime()
  },
  computed: {
    ...mapGetters({
      $auth: 'auth/getLoggedIn'
    })
  },
  methods: {
    async getWaitingTime() {
      let x = await this.$api.get('/api/quiz/wait-until').then(res=> res.data.wait_until)
      if(x) {
        let compareDate = new Date(x);
        let that = this
        this.timer = setInterval(function() {
          that.timeBetweenDates(compareDate);
        }, 1000);
      }else{
        this.timer = 0
      }
    },
    timeBetweenDates(toDate) {
      var dateEntered = toDate;
      var now = new Date();
      var difference = dateEntered.getTime() - now.getTime();

      if (difference <= 0) {
        // Timer done
        clearInterval(this.timer);
        this.timer = 0
      } else {

        var seconds = Math.floor(difference / 1000);
        var minutes = Math.floor(seconds / 60);
        var hours = Math.floor(minutes / 60);

        hours %= 24;
        minutes %= 60;
        seconds %= 60;

        this.text = `${hours} jam ${minutes} menit ${seconds} detik.`
      }
    }
  }
}
</script>

<style>

</style>
