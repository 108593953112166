<template>
<div class="alert" @click="goTo(link)">
  <div class="media">
    <slot name="start"></slot>
  </div>
  <div class="content">
    <div class="title">{{ title }}</div>
    <div class="desc">{{ desc }}</div>
  </div>
  <div class="action">
    <slot name="end"></slot>
  </div>
</div>
</template>

<script>
import { defineComponent } from 'vue'
export default defineComponent({
  props: {
    title: String,
    desc: String,
    link: String
  },
  methods: {
    goTo(path) {
      this.$router.push(path)
    }
  }
})
</script>

<style lang="scss">
.alert {
  display: flex;
  align-content: center;
  justify-content: flex-start;
  align-items: center;
  padding: 11px 15px;
  background-color: #EAEAEA;
  border-radius: 5px;
  .content {
    flex: 1;
    line-height: 22px;
    .title {
      font-weight: bold;
      font-size: 16px;
    }
    .desc {
      background: transparent !important;
      padding: 0;
    }
  }
  .media {
    width: 15%;
    text-align: center;
  }
  .action {
    width: 10px;
  }
}
</style>
