<template>
  <ion-page>
    <Header />
    <ion-content :fullscreen="true">
      <div class="box-container flex">
        <div class="flex-half">
          <div class="div-name">
            Hei, <span>{{ $auth ? $auth.name : 'Guest!' }}</span>
          </div>
          <div class="div-point" @click.prevent="$router.push('/history')">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clip-path="url(#clip0)">
              <path d="M11.9794 23.9587C18.5954 23.9587 23.9588 18.5954 23.9588 11.9794C23.9588 5.36334 18.5954 -1.52588e-05 11.9794 -1.52588e-05C5.36335 -1.52588e-05 0 5.36334 0 11.9794C0 18.5954 5.36335 23.9587 11.9794 23.9587Z" fill="#FFBC51"/>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M11.9797 2.43952C12.3952 2.43952 12.8045 2.466 13.206 2.5176C13.5726 2.56462 13.9084 2.30529 13.9555 1.93872C14.0027 1.57214 13.7433 1.23629 13.3767 1.18922C12.9193 1.13041 12.453 1.1001 11.9797 1.1001C11.6101 1.1001 11.3101 1.40015 11.3101 1.76974C11.3101 2.13956 11.6101 2.43952 11.9797 2.43952Z" fill="#FCD28A"/>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M14.8733 2.88701C17.2311 3.6376 19.1921 5.28124 20.3591 7.41888C20.5361 7.7435 20.9433 7.86312 21.2677 7.68597C21.5921 7.50882 21.7117 7.10156 21.5346 6.7774C20.2041 4.3398 17.9678 2.46697 15.2797 1.61082C14.9276 1.49864 14.5506 1.6935 14.4384 2.04556C14.3263 2.39803 14.5212 2.7747 14.8733 2.88701Z" fill="#FCD28A"/>
              <path d="M11.9788 20.5844C16.7314 20.5844 20.5841 16.7317 20.5841 11.9792C20.5841 7.22661 16.7314 3.3739 11.9788 3.3739C7.22624 3.3739 3.37354 7.22661 3.37354 11.9792C3.37354 16.7317 7.22624 20.5844 11.9788 20.5844Z" fill="#FFB32C"/>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M8.04716 19.6344C5.27264 18.2057 3.37354 15.3123 3.37354 11.9791C3.37354 7.22997 7.22945 3.3739 11.9788 3.3739C14.0876 3.3739 16.0201 4.13408 17.5176 5.39569C16.3391 4.78819 15.0022 4.44533 13.586 4.44533C8.83659 4.44533 4.98068 8.3014 4.98068 13.0505C4.98068 15.6916 6.1727 18.0557 8.04716 19.6344Z" fill="#F49E17"/>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M11.3146 7.14659C11.4346 6.88772 11.6939 6.72217 11.9791 6.72217C12.2642 6.72217 12.5235 6.88772 12.6436 7.14659L13.8886 9.82854L16.8243 10.184C17.1074 10.2181 17.3449 10.4134 17.433 10.685C17.5211 10.9562 17.4439 11.2539 17.235 11.4478L15.0688 13.461L15.6381 16.3626C15.693 16.6425 15.5805 16.9286 15.3498 17.0964C15.1191 17.2641 14.8121 17.2826 14.5629 17.1436L11.9791 15.7058L9.39527 17.1436C9.1461 17.2826 8.83909 17.2641 8.60836 17.0964C8.37767 16.9286 8.26519 16.6425 8.32009 16.3626L8.88934 13.461L6.72319 11.4478C6.51428 11.2539 6.43702 10.9562 6.52514 10.685C6.61329 10.4134 6.85077 10.2181 7.13389 10.184L10.0695 9.82854L11.3146 7.14659Z" fill="white"/>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M8.1618 10.0594C9.27244 13.1575 12.1111 15.4359 15.5141 15.7305L15.6381 16.3627C15.693 16.6423 15.5805 16.9284 15.3498 17.0961C15.1191 17.2637 14.812 17.2825 14.5629 17.1437L11.9791 15.7053L9.39528 17.1437C9.14606 17.2825 8.8391 17.2637 8.60837 17.0961C8.37764 16.9284 8.26519 16.6423 8.3201 16.3627L8.88935 13.4607L6.72319 11.448C6.51431 11.2536 6.43701 10.9562 6.52513 10.6846C6.61326 10.4136 6.85079 10.218 7.13386 10.1837L8.1618 10.0594Z" fill="#E9F2FE"/>
              </g>
              <defs>
              <clipPath id="clip0">
              <rect width="24" height="24" fill="white"/>
              </clipPath>
              </defs>
            </svg>
            {{ $filters.numFormat($auth? $auth.point: 0) }}
          </div>
        </div>
        <div class="flex-half">
          <quiz-button></quiz-button>
        </div>
      </div>
      <div class="box-container bb">
        <alert title="Selamat! hadiah telah sampai" desc="Yuk berikan testimoni sekarang" :link="`/testimoni/${review.id}/create`" v-if="review">
          <template v-slot:start>
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M10 0L13.09 6.26L20 7.27L15 12.14L16.18 19.02L10 15.77L3.82 19.02L5 12.14L0 7.27L6.91 6.26L10 0Z" fill="#F2C94C"/>
            </svg>
          </template>
          <template v-slot:end>
            <svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M1 9L5 5L1 1" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </template>
        </alert>
      </div>
      <div class="box-container bb">
        <div class="header">
          <div class="title">
            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M10.5 1.5H4.5C4.10218 1.5 3.72064 1.65804 3.43934 1.93934C3.15804 2.22064 3 2.60218 3 3V15C3 15.3978 3.15804 15.7794 3.43934 16.0607C3.72064 16.342 4.10218 16.5 4.5 16.5H13.5C13.8978 16.5 14.2794 16.342 14.5607 16.0607C14.842 15.7794 15 15.3978 15 15V6L10.5 1.5Z" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M10.5 1.5V6H15" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M12 9.75H6" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M12 12.75H6" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M7.5 6.75H6.75H6" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
            Info Terbaru
          </div>
          <a @click="$router.push('/news')">
            Lihat Semua
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M6 12L10 8L6 4" stroke="#545454" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </a>
        </div>
        <ul class="list">
          <li class="item" v-for="n in news" :key="n.id" @click="$router.push({name: 'PostDetail', params: {slug: n.slug}})">
            <div class="date text-sm">{{ n.created_at }}</div>
            {{ n.title }}
          </li>
        </ul>
      </div>
      <div class="box-container bb">
        <div class="header">
          <div class="title">
            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clip-path="url(#clip0)">
              <path d="M9 11.25C11.8995 11.25 14.25 8.8995 14.25 6C14.25 3.10051 11.8995 0.75 9 0.75C6.10051 0.75 3.75 3.10051 3.75 6C3.75 8.8995 6.10051 11.25 9 11.25Z" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M6.1575 10.4175L5.25 17.25L9 15L12.75 17.25L11.8425 10.41" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              </g>
              <defs>
              <clipPath id="clip0">
              <rect width="18" height="18" fill="white"/>
              </clipPath>
              </defs>
            </svg>
            Ikuti Giveaway
          </div>
          <a @click="$router.push('/event')">
            Lihat Semua
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M6 12L10 8L6 4" stroke="#545454" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </a>
        </div>
        <ul class="list">
          <li class="item flex align-center" v-for="e in events" :key="e.id" :class="{'active': (e.active == 1)}" @click="$router.push({name: 'EventDetail', params: {id: e.id} })">
            <div class="media">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M20 12V22H4V12" :stroke="e.active  == 1? '#F2C94C': '#C4C4C4'" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M22 7H2V12H22V7Z" :stroke="e.active  == 1 ? '#F2C94C': '#C4C4C4'" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M12 22V7" :stroke="e.active  == 1 ? '#F2C94C': '#C4C4C4'" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M12 7H7.5C6.83696 7 6.20107 6.73661 5.73223 6.26777C5.26339 5.79893 5 5.16304 5 4.5C5 3.83696 5.26339 3.20107 5.73223 2.73223C6.20107 2.26339 6.83696 2 7.5 2C11 2 12 7 12 7Z" :stroke="e.active  == 1 ? '#F2C94C': '#C4C4C4'" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M12 7H16.5C17.163 7 17.7989 6.73661 18.2678 6.26777C18.7366 5.79893 19 5.16304 19 4.5C19 3.83696 18.7366 3.20107 18.2678 2.73223C17.7989 2.26339 17.163 2 16.5 2C13 2 12 7 12 7Z" :stroke="e.active  == 1 ? '#F2C94C': '#C4C4C4'" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </div>
            <div class="content flex-half">
              <div class="a text-bold">{{ e.name }}</div>
              <div class="b" v-if="e.active == 1">Sedang berlangsung</div>
              <div class="b" v-if="e.active == 2 || e.active == 0">{{ e.winners_count }} pemenang | {{ e.testimoni_count }} testimoni</div>
            </div>
            <div class="end" style="padding: 13px;">
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M6 12L10 8L6 4" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </div>
          </li>
        </ul>
      </div>
      <div class="box-container">
        <div class="header">
          <div class="title">
            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M4.5 1.5L2.25 4.5V15C2.25 15.3978 2.40804 15.7794 2.68934 16.0607C2.97064 16.342 3.35218 16.5 3.75 16.5H14.25C14.6478 16.5 15.0294 16.342 15.3107 16.0607C15.592 15.7794 15.75 15.3978 15.75 15V4.5L13.5 1.5H4.5Z" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M2.25 4.5H15.75" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M12 7.5C12 8.29565 11.6839 9.05871 11.1213 9.62132C10.5587 10.1839 9.79565 10.5 9 10.5C8.20435 10.5 7.44129 10.1839 6.87868 9.62132C6.31607 9.05871 6 8.29565 6 7.5" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
            Official Store
          </div>
          <a @click="$router.push('/store')">
            Lihat Semua
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M6 12L10 8L6 4" stroke="#545454" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </a>
        </div>
      </div>
      <ion-grid>
        <ion-row>
          <ion-col size="6" v-for="item in items" :key="item.id">
            <product :item="item" @click="detail(item.id)"></product>
          </ion-col>
        </ion-row>
      </ion-grid>
      <ion-infinite-scroll
        @ionInfinite="getCatalog"
        threshold="100px"
        id="infinite-scroll"
        :disabled="isDisabled"
      >
        <ion-infinite-scroll-content
          loading-spinner="bubbles"
          loading-text="Loading more data...">
        </ion-infinite-scroll-content>
      </ion-infinite-scroll>
    </ion-content>
  </ion-page>
</template>

<script>
import { IonContent, IonPage,  IonGrid, IonRow, IonCol, IonInfiniteScroll, IonInfiniteScrollContent  } from '@ionic/vue'
import { defineComponent } from 'vue'
import Header from '@/components/header'
import { mapGetters } from 'vuex'
import Alert from '@/components/alert'
import Product from '@/components/store/product'
import QuizButton from '@/components/QuizButton'

export default defineComponent({
  components: {
    QuizButton,
    IonContent,
    IonPage,
    Alert,
    Header,
    IonGrid, IonRow, IonCol, IonInfiniteScroll, IonInfiniteScrollContent, Product
  },
  created () {
    this.getCatalog()
    this.getNews()
    this.getEvents()
    this.checkReview()
  },
  data () {
    return {
      items: [],
      page: 1,
      isDisabled: false,
      news: [],
      events: [],
      review: null
    }
  },
  computed: {
    ...mapGetters({
      $auth: 'auth/getLoggedIn'
    })
  },
  methods: {
    async getCatalog (e) {
      let data = await this.$api.get('/api/store/product', {
          params: {page: this.page, filter: this.filter }
        }).then(res => res)

      if(data.meta.last_page == this.page) {
        this.isDisabled = true;
      }

      data.data.forEach(c => {
        this.items.push(c)
      })
      if(e && e.type == 'ion-infinite')
        e.target.complete();

      this.page++
    },
    detail (id) {
      this.$router.push({name: 'ProductDetail', params: {id: id}})
    },
    async getNews() {
      this.news = await this.$api.get('/api/post', {params: {per_page: 3}}).then(res => res.data)
    },
    async getEvents() {
      this.events = await this.$api.get('/api/event', {params: {per_page: 5}})
        .then(res => res.data)
    },
    async checkReview() {
      this.review = await this.$api.get('/api/is-reviewed').then(res => res.data)
    }
  }
})
</script>

<style lang="scss">
.div-name {
  font-size: 16px;
  line-height: 22px;
  span {
    font-weight: bold;
  }
}
.div-point {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-weight: bold;
  font-size: 24px;
  line-height: 33px;
  svg {
    margin-right: 5px;
  }
}
.box-container {
  .header {
    a {
      cursor: pointer;
    }
  }
}

</style>
